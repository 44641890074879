import { throws } from 'assert';
import React from 'react';

import moment from 'moment';
import Loader from "react-loader-spinner";

import NewsItem from "./newsItem";

import BreadCrumb from '../shared/BreadCrumb';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";


export default class NewsList extends React.Component {
	constructor(props) {
		super(props);
 		this.state = {
            items: [],
            breadTitle: 'Our News',
            itmTitle: 'United housing and development News.'
        };
	}

    componentDidMount() {
		this.apiCall();
	}

    apiCall(){
		let urlHost = 'https://zulu-fashion.com/wp-json/wp/v2/news';
		

		const url = urlHost;
        let requestheaders = new Headers();
        requestheaders.append('Content-Type', 'application/json')
        requestheaders.append("accept", "application/json")
 
        const requestOptions = {
            method: 'GET',
            headers: requestheaders,
        };
		
        fetch(url, requestOptions).then((response) => response.json())
		.then(result => {
			this.setState({
                items: result,
            })
		})
	}

    render() {
        // var newsItem = this.state.items.map((i)=>{
        //     return(
                
        //     )
        // });

        var newsListItems = this.state.items.map((i)=>{
            return(
                <NewsItem data={i}></NewsItem>
            )
        });

        return(
            <div className='innerPage'>
                    <BreadCrumb
                    title={this.state.breadTitle}
                    itmTitle={this.state.itmTitle}></BreadCrumb>


                    <div id='NewsList'>
                        <div className='container'>
                            <div className='row'>
                                {(!this.state.items || this.state.items.length == 0)
                                    ? (
                                        <div className='loader'>
                                            <Loader
                                                        type="Grid"
                                                        color="#24B5E7"
                                                        height={100}
                                                        width={100}
                                                    />
                                        </div>
                                    )
                                    : newsListItems
                                }
                                

                            </div>
                        </div>
                    </div>
            </div>
            
        )
    }

}