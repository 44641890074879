import { throws } from 'assert';
import React from 'react';

import BreadCrumb from '../shared/BreadCrumb';
import Loader from "react-loader-spinner";

export default class NewsDetails extends React.Component {
	constructor(props) {
		super(props);
 		this.state = {
            items: [],
            breadTitle: 'Our News',
            itmTitle: '',

        };
	}
    
    componentDidMount() {
		this.apiCall();
	}

    // getIdFromUrl(){
	// 	let urlHostId = window.location.href;
	// 	let urlHost = window.location.host;
	// 	let ids = urlHostId.split("=");
	// 	let id = ids[ids.length - 1];
	// 	this.setState({
	// 		itemId: Number(id)
	// 	},()=>{
    //         this.apiCall();
    //     })
    
    // }

    apiCall(){
        let urlHostId = window.location.href;
		// let urlHost = window.location.host;
		let ids = urlHostId.split("=");
		let id = ids[ids.length - 1];
		let urlHost = 'https://zulu-fashion.com/wp-json/wp/v2/news/'+id;
		

		const url = urlHost;
        let requestheaders = new Headers();
        requestheaders.append('Content-Type', 'application/json')
        requestheaders.append("accept", "application/json")
 
        const requestOptions = {
            method: 'GET',
            headers: requestheaders,
        };
		
        fetch(url, requestOptions).then((response) => response.json())
		.then(result => {
            this.setState({
                items: result,
                title: result.title.rendered,
                desc: result.content.rendered,
            })
			
		})
	}

    render() {
        return(
            <div className='innerPage'>

                <BreadCrumb
                 title={this.state.breadTitle}
                 itmTitle={this.state.title}
                 itmDate={this.state.items.date}></BreadCrumb>


                <div id='NewsDetails'>
                    <div className='container'>
                    {(!this.state.desc || this.state.desc.length == 0)
                        ? (
                            <div className='loader'>
                                <Loader
                                            type="Grid"
                                            color="#24B5E7"
                                            height={100}
                                            width={100}
                                        />
                            </div>
                        )
                        : <div className='' dangerouslySetInnerHTML={{ __html: this.state.desc }} />
                    }
                        
                    </div>
                </div>
            </div>
            
        )
    }

}