import { throws } from 'assert';
import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

import moment from 'moment';

export default class NewsItem extends React.Component{
    constructor(props) {
		super(props);
 		this.state = {
            imgUrl: "",
        };
	}

    componentDidMount(){
        this.imageApi()
    }

    imageApi(){
        let urlHost = 'https://zulu-fashion.com/wp-json/wp/v2/media/'+this.props.data.featured_media;
		

		const url = urlHost;
        let requestheaders = new Headers();
        requestheaders.append('Content-Type', 'application/json')
        requestheaders.append("accept", "application/json")
 
        const requestOptions = {
            method: 'GET',
            headers: requestheaders,
        };
		
        fetch(url, requestOptions).then((response) => response.json())
		.then(result => {
            console.log(urlHost)
			this.setState({
                imgUrl: result.guid.rendered,
            })
		})
    }

    render() {
        return(
            <div className='col-4' key={this.props.data.id}>
                    <Link className='newItem' to={"/news-details?id="+this.props.data.id}>
                        <div className='newImg'>
                            {/* <img src={"http://3.138.198.198:8055/assets/"+i.Image} /> */}
                            <img src={this.state.imgUrl}/>
                        </div>
                        <div className='newInfo'>
                            {/* <span className='newDate'>{moment(i.date_created).format('DD MMM')}</span> */}
                            <span className='newTxt'>
                                <h1>{this.props.data.title.rendered}</h1>
                                <p dangerouslySetInnerHTML={{ __html: this.props.data.excerpt.rendered }} />
                                    {/* {i.Brief.substring(0, 150) }
                                    { i.Brief.length >= 150 && `...` }
                                </p> */}
                            </span>
                        </div>
                    </Link>
                </div>
        )
    }

}